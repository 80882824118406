import { ComponentChildren, h, JSX } from 'preact';
import { WistiaPlayerProvider } from '../hooks/useWistiaPlayerContext.tsx';
import { TranscriptContextProvider } from '../hooks/useTranscriptContext.tsx';
import { ScrollStrategyProvider } from '../hooks/useScrollStrategy.tsx';
import { PlayerCaptionsLanguageProvider } from '../hooks/usePlayerCaptionsLanguage.tsx';
import { TranscriptSignalsProvider } from '../hooks/useTranscriptSignals.tsx';
import { TranscriptSignals } from '../utilities/createSignals.ts';

export type TranscriptProvidersProps = {
  autoScrollMarginTopPx: number;
  children: ComponentChildren;
  hasAutoScrollControl: boolean;
  hasDownloadControl: boolean;
  mediaHashedId: string;
  playerDomId?: string | null;
  signals: TranscriptSignals;
};

export const TranscriptProviders = ({
  children,
  mediaHashedId,
  playerDomId,
  autoScrollMarginTopPx,
  signals,
  hasAutoScrollControl,
  hasDownloadControl,
}: TranscriptProvidersProps): JSX.Element => {
  return (
    <TranscriptSignalsProvider signals={signals}>
      <WistiaPlayerProvider mediaHashedId={mediaHashedId} playerDomId={playerDomId}>
        <PlayerCaptionsLanguageProvider>
          <TranscriptContextProvider
            autoScrollMarginTopPx={autoScrollMarginTopPx}
            mediaHashedId={mediaHashedId}
            hasAutoScrollControl={hasAutoScrollControl}
            hasDownloadControl={hasDownloadControl}
          >
            <ScrollStrategyProvider>{children}</ScrollStrategyProvider>
          </TranscriptContextProvider>
        </PlayerCaptionsLanguageProvider>
      </WistiaPlayerProvider>
    </TranscriptSignalsProvider>
  );
};
