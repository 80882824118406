import { h, render } from 'preact';
import { isEmptyString, isNil, isNonEmptyString, isNull } from '@wistia/type-guards';
import { Transcript } from './Transcript.tsx';
import '../../utilities/interFontFace.js';
import { Font } from '../../types/font.ts';
import { TranscriptProviders } from './components/TranscriptProviders.tsx';
import { createSignals, TranscriptSignals } from './utilities/createSignals.ts';
import { countMetric } from '../../utilities/simpleMetrics.js';

const REQUIRED_ATTRIBUTES = ['media-id', 'media-hashed-id'];

const OPTIONAL_PUBLIC_ATTRIBUTES = [
  'font-family',
  'font-url',
  'accent-color',
  'auto-scroll-margin-top-px',
  'has-auto-scroll-control',
  'has-download-control',
];

const OPTIONAL_INTERNAL_ATTRIBUTES = ['embed-host', 'player-dom-id'];

// regex that checks any domains that end in .wistia.io or .wistia.com
const WISTIA_DOMAIN_REGEX = /wistia\.(io|com)$/;

/*
 * The wistia-transcript web component. This is where we define the top-level API, and start the render tree
 * for the preact-based transcript. The entry point for that transcript is the Transcript.tsx component.
 * */
export class WistiaTranscript extends HTMLElement {
  #preactRoot: HTMLDivElement | null = null;

  #signals: TranscriptSignals;

  public constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  public static get observedAttributes(): string[] {
    return [...REQUIRED_ATTRIBUTES, ...OPTIONAL_PUBLIC_ATTRIBUTES, ...OPTIONAL_INTERNAL_ATTRIBUTES];
  }

  public get accentColor(): string | null | undefined {
    return this.getAttribute('accent-color');
  }

  public set accentColor(value: string) {
    this.setAttribute('accent-color', value);
    this.#renderTranscript();
  }

  public set autoScrollMarginTopPx(value: string) {
    this.setAttribute('auto-scroll-margin-top-px', value);
    this.#renderTranscript();
  }

  public get autoScrollMarginTopPx(): string | null | undefined {
    return this.getAttribute('auto-scroll-margin-top-px');
  }

  public get embedHost(): string | null | undefined {
    return this.getAttribute('embed-host');
  }

  public set embedHost(value: string) {
    this.setAttribute('embed-host', value);
    this.#renderTranscript();
  }

  public get fontFamily(): string | null | undefined {
    return this.getAttribute('font-family');
  }

  public set fontFamily(value: string) {
    this.setAttribute('font-family', value);
    this.#renderTranscript();
  }

  public get fontUrl(): string | undefined {
    const url = this.getAttribute('font-url');

    return isNull(url) || isEmptyString(url) ? undefined : url;
  }

  public set fontUrl(value: string) {
    this.setAttribute('font-url', value);
    this.#renderTranscript();
  }

  public get hasDownloadControl(): boolean {
    const hasDownloadControlAttribute = this.getAttribute('has-download-control');

    // if the attribute is not set, default to true
    if (isNil(hasDownloadControlAttribute) || isEmptyString(hasDownloadControlAttribute)) {
      return true;
    }

    return hasDownloadControlAttribute === 'true';
  }

  public set hasDownloadControl(value: boolean) {
    this.setAttribute('has-download-control', String(value));
    this.#renderTranscript();
  }

  public get mediaHashedId(): string | null | undefined {
    return this.getAttribute('media-hashed-id');
  }

  public set mediaHashedId(value: string) {
    this.setAttribute('media-hashed-id', value);
    this.#renderTranscript();
  }

  public get mediaId(): string | null | undefined {
    return this.getAttribute('media-id') ?? '';
  }

  public set mediaId(value: string) {
    this.setAttribute('media-id', value);
    this.#renderTranscript();
  }

  public set playerDomId(value: string) {
    this.setAttribute('player-dom-id', value);
    this.#renderTranscript();
  }

  public get playerDomId(): string | null | undefined {
    return this.getAttribute('player-dom-id');
  }

  get #hasAutoScrollControl(): boolean {
    const hasAutoScrollControlAttribute = this.getAttribute('has-auto-scroll-control');

    // if the attribute is not set, default to true
    if (isNil(hasAutoScrollControlAttribute) || isEmptyString(hasAutoScrollControlAttribute)) {
      return true;
    }

    return hasAutoScrollControlAttribute === 'true';
  }

  set #hasAutoScrollControl(value: boolean) {
    this.setAttribute('has-auto-scroll-control', String(value));
    this.#renderTranscript();
  }

  protected attributeChangedCallback(
    name: string,
    oldValue: string | null,
    newValue: string | null,
  ): void {
    if (oldValue === newValue) {
      return;
    }

    switch (name) {
      case 'accent-color':
        this.accentColor = newValue ?? '';
        break;
      case 'embed-host':
        this.embedHost = newValue ?? '';
        break;
      case 'font-family':
        this.fontFamily = newValue ?? '';
        break;
      case 'font-url':
        this.fontUrl = newValue ?? '';
        break;
      case 'media-id':
        this.mediaId = newValue ?? '';
        break;
      case 'media-hashed-id':
        this.mediaId = newValue ?? '';
        break;
      case 'player-dom-id':
        this.playerDomId = newValue ?? '';
        break;
      case 'auto-scroll-margin-top-px':
        this.autoScrollMarginTopPx = newValue ?? '';
        break;
      case 'has-auto-scroll-control':
        this.#hasAutoScrollControl = newValue === 'true';
        break;
      case 'has-download-control':
        this.hasDownloadControl = newValue === 'true';
        break;
      default:
        break;
    }
  }

  protected connectedCallback(): void {
    this.#signals = createSignals();
    this.#preactRoot = document.createElement('div');
    this.#preactRoot.className = 'app-root';

    this.#renderTranscript();

    this.shadowRoot?.append(this.#preactRoot);

    const isNotAWistiaDomain = !WISTIA_DOMAIN_REGEX.test(window.location.hostname);
    const isNotLocalhost = window.location.hostname !== 'localhost';

    if (isNotAWistiaDomain && isNotLocalhost) {
      const location =
        window.location.protocol.length && window.location.hostname.length
          ? `${window.location.protocol}//${window.location.hostname}`
          : null;

      if (location != null) {
        countMetric('transcript/init-external-embed', 1, {
          mediaHashedId: this.mediaId ?? this.mediaHashedId,
          embedHost: this.embedHost,
          location,
        });
      }
    }
  }

  #renderTranscript(): void {
    if (!this.#preactRoot) {
      return;
    }

    const font: Font | undefined = isNonEmptyString(this.fontFamily)
      ? { family: this.fontFamily, url: this.fontUrl }
      : undefined;

    this.#signals.currentWordElement.value = null;
    this.#signals.timedWordElementsInCurrentCue.value = [];

    render(
      <TranscriptProviders
        mediaHashedId={this.mediaId ?? this.mediaHashedId ?? ''}
        playerDomId={this.playerDomId}
        autoScrollMarginTopPx={Number(this.autoScrollMarginTopPx ?? 0)}
        signals={this.#signals}
        hasAutoScrollControl={this.#hasAutoScrollControl}
        hasDownloadControl={this.hasDownloadControl}
      >
        <Transcript
          embedHost={this.embedHost}
          font={font}
          accentColor={this.accentColor}
          onInitialRender={() => {
            this.dispatchEvent(new CustomEvent('initial-render'));
          }}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          shadowRoot={this.shadowRoot!}
        />
      </TranscriptProviders>,
      this.#preactRoot,
    );
  }
}

if (customElements.get('wistia-transcript') === undefined) {
  customElements.define('wistia-transcript', WistiaTranscript);
}
