import { isNotNil, Nilable } from '@wistia/type-guards';
import { CaptionsData, TranscriptDocument } from '../types.ts';
import { Font } from '../../../types/font.ts';
import { cdnFastWistiaNetHost } from '../../../utilities/hosts.js';

const FAST_HOSTNAME: string = cdnFastWistiaNetHost();

export type TranscriptApiResponseData = {
  captions_data: CaptionsData;
  document: TranscriptDocument;
  embed_options?: {
    font: Font;
  };
  language: string;
  media_hashed_id: string;
};

const getQueryParams = (
  transcriptIetfLanguageTag?: string,
  audioDescriptionIetfLanguageTag?: string,
): string => {
  const queryParams = new URLSearchParams();

  if (isNotNil(transcriptIetfLanguageTag) && transcriptIetfLanguageTag !== '_off_') {
    queryParams.set('transcript_ietf_language_tag', transcriptIetfLanguageTag);
  }

  if (isNotNil(audioDescriptionIetfLanguageTag)) {
    queryParams.set('ead_ietf_language_tag', audioDescriptionIetfLanguageTag);
  }

  queryParams.set('load_strategy', 'readable');

  return queryParams.toString();
};

export class TranscriptApi {
  private readonly embedHost: string;

  public constructor(embedHost?: Nilable<string>) {
    this.embedHost = embedHost ?? FAST_HOSTNAME;
  }

  public async get({
    mediaHashedId,
    transcriptIetfLanguageTag,
    audioDescriptionIetfLanguageTag,
  }: {
    audioDescriptionIetfLanguageTag?: string;
    mediaHashedId: string;
    transcriptIetfLanguageTag?: string;
  }): Promise<TranscriptApiResponseData> {
    const url = new URL(`https://${this.embedHost}/embed/time_coded_transcripts/${mediaHashedId}`);
    url.search = getQueryParams(transcriptIetfLanguageTag, audioDescriptionIetfLanguageTag);

    const response = await fetch(url.toString());

    if (!response.ok) {
      const error = await response.text();
      return Promise.reject(new Error(error));
    }

    return (await response.json()) as TranscriptApiResponseData;
  }
}
