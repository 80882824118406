import { ComponentChildren, createContext, h, JSX } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import { PublicApi } from 'src/types/player-api-types.ts';
import { useWistiaPlayer } from '../../shared/hooks/useWistiaPlayer.ts';
import { TranscriptProps } from '../Transcript.tsx';
import { ExtendedAudioDescriptionControl } from '../types.ts';

type WistiaPlayerContextValue = {
  audioDescriptionLanguage: string | undefined;
  player: PublicApi;
};

const WistiaPlayerContext = createContext<WistiaPlayerContextValue | null>(null);

export const WistiaPlayerProvider = ({
  children,
  mediaHashedId,
  playerDomId,
}: {
  children: ComponentChildren;
  mediaHashedId: string;
  playerDomId: TranscriptProps['playerDomId'];
}): JSX.Element | null => {
  const player = useWistiaPlayer(playerDomId ?? mediaHashedId);
  const [audioDescriptionLanguage, setAudioDescriptionLanguage] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    const eadControl = player?._impl?.controls.extendedAudioDescriptionButton as
      | ExtendedAudioDescriptionControl
      | undefined;
    // If EAD is already enabled when this initializes, make sure we set it.
    if (eadControl?.selectedLanguage != null && eadControl.selectedLanguage !== '_off_') {
      setAudioDescriptionLanguage(eadControl.selectedLanguage);
    }

    // bind returns it's own unbind function
    return player?.bind('extendedaudiodescriptionchange', (language: string) => {
      setAudioDescriptionLanguage(language === '_off_' ? undefined : language);
    });
  }, [player]);

  if (player == null) {
    return null;
  }

  return (
    <WistiaPlayerContext.Provider
      value={{
        player,
        audioDescriptionLanguage,
      }}
    >
      {children}
    </WistiaPlayerContext.Provider>
  );
};

export const useWistiaPlayerContext = (): WistiaPlayerContextValue => {
  const context = useContext(WistiaPlayerContext);
  if (context == null) {
    throw new Error('useWistiaPlayerContext must be used within a WistiaPlayerProvider');
  }
  return context;
};
